import React, { useState, useEffect } from 'react'
import { Trash2, Edit, ChevronDown, ChevronUp } from 'lucide-react'
import { getFurniture, deleteFurniture } from '../db/localDatabase'

interface Furniture {
  id: number
  name: string
  quantity: number
  model: string
  imageUrl: string
}

interface FurnitureListProps {
  updateFurnitureCount: () => void;
}

const FurnitureList: React.FC<FurnitureListProps> = ({ updateFurnitureCount }) => {
  const [furniture, setFurniture] = useState<Furniture[]>([])
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  useEffect(() => {
    loadFurniture()
  }, [])

  const loadFurniture = () => {
    const loadedFurniture = getFurniture()
    setFurniture(loadedFurniture)
    updateFurnitureCount()
  }

  const handleDelete = (id: number) => {
    if (window.confirm('Tem certeza que deseja excluir este móvel?')) {
      deleteFurniture(id)
      loadFurniture()
    }
  }

  const handleEdit = (id: number) => {
    // Implementar a lógica de edição aqui
    console.log('Editar móvel com ID:', id)
  }

  const toggleExpand = (id: number) => {
    setExpandedRow(expandedRow === id ? null : id)
  }

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Lista de Móveis</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Nome</th>
              <th className="py-3 px-6 text-left">Quantidade</th>
              <th className="py-3 px-6 text-left">Modelo</th>
              <th className="py-3 px-6 text-center">Ações</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {furniture.map((item) => (
              <React.Fragment key={item.id}>
                <tr className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left whitespace-nowrap">
                    <div className="flex items-center">
                      <span className="font-medium">{item.name}</span>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <div className="flex items-center">
                      <span>{item.quantity}</span>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <div className="flex items-center">
                      <span>{item.model}</span>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <div className="flex item-center justify-center">
                      <button onClick={() => handleEdit(item.id)} className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                        <Edit size={16} />
                      </button>
                      <button onClick={() => handleDelete(item.id)} className="w-4 mr-2 transform hover:text-red-500 hover:scale-110">
                        <Trash2 size={16} />
                      </button>
                      <button onClick={() => toggleExpand(item.id)} className="w-4 transform hover:text-blue-500 hover:scale-110">
                        {expandedRow === item.id ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                      </button>
                    </div>
                  </td>
                </tr>
                {expandedRow === item.id && (
                  <tr className="bg-gray-50">
                    <td colSpan={4} className="py-3 px-6">
                      <div className="flex items-center space-x-4">
                        <img src={item.imageUrl} alt={item.name} className="w-24 h-24 object-cover rounded" />
                        <div>
                          <p className="font-medium">Detalhes do Móvel</p>
                          <p>Nome: {item.name}</p>
                          <p>Quantidade: {item.quantity}</p>
                          <p>Modelo: {item.model}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FurnitureList